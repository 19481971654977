import * as Sentry from '@sentry/browser';
import { User } from 'entrypoint';

import { SENTRY_DSN } from '.';

let isSentryEnabled = false;

export const initSentry = () => {
  isSentryEnabled = true;

  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.browserProfilingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.2,
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      /^https:\/\/evidence\.count-ai\.stathack\.jp/,
      /^https:\/\/evidence\.count-ai\.stathack-dev\.jp/,
    ],
    // Profiling
    profilesSampleRate: 0.2,
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
};

export const sentrySetUser = (user: User) => {
  if (isSentryEnabled) {
    const scope = Sentry.getCurrentScope();
    scope.setUser({
      id: user.id,
      username: user.display_name ?? '',
    });
  }
};

// export const sentrySetOrganization = (organization: Organization) => {
//   if (isSentryEnabled) {
//     Sentry.setTag('organization', organization.id);
//   }
// };
