import { useState } from 'react';

import { Button } from '@/components/Button';
import {
  Dialog,
  DialogContent,
  DialogClose,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/Dialog';

interface WorkerDialogProps {
  triggerText: string;
  titleText: string;
  saveText: string;
  initialName: string;
  colorVariant: 'primary' | 'ghost';
  onSave: (name: string) => void;
}

const WorkerDialog = ({
  triggerText,
  titleText,
  saveText,
  initialName,
  colorVariant,
  onSave,
}: WorkerDialogProps) => {
  const [name, setName] = useState(initialName);
  const [isOpen, setIsOpen] = useState(false);

  const handleSave = () => {
    onSave(name);
    setIsOpen(false);
  };

  const handleClose = () => {
    setName(initialName);
  };

  return (
    <Dialog
      open={isOpen}
      onOpenChange={(isOpen) => {
        setIsOpen(isOpen);
        if (!isOpen) handleClose();
      }}
    >
      <DialogTrigger asChild>
        <Button colorVariant={colorVariant}>
          <div
            className="flex items-center justify-center gap-2"
            style={
              colorVariant === 'ghost'
                ? { fontWeight: 'normal', color: '#0967D2' }
                : {}
            }
          >
            {triggerText}
          </div>
        </Button>
      </DialogTrigger>
      <DialogContent style={{ maxWidth: '500px', maxHeight: '300px' }}>
        <div className="flex h-full flex-col space-y-8 p-6">
          <DialogHeader>
            <DialogTitle>{titleText}</DialogTitle>
          </DialogHeader>
          <div>
            <label htmlFor="worker-name" className="mb-1 block">
              表示名
            </label>
            <input
              type="text"
              id="worker-name"
              className="w-full rounded-md border px-3 py-2"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="flex w-full space-x-4">
            <DialogClose asChild>
              <Button
                isFullWidth
                colorVariant="cancel"
                className="bg-cool-grey-100"
              >
                キャンセル
              </Button>
            </DialogClose>
            <Button isFullWidth onClick={handleSave}>
              {saveText}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default WorkerDialog;
