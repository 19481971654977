import { Navigate, RouteObject } from 'react-router-dom';

import { ProtectedRoute } from './ProtectedRoute';

import { WorkerManagementPage } from '@/features/WorkersTable/routes/WorkerManagementPage';
import { lazyImport } from '@/utils/lazyImport';

const { TablePage } = lazyImport(
  () => import('@/features/Table/routes/TablePage'),
  'TablePage',
);

/**
 * routes for authenticated users only
 */
export const routesForAuthenticatedOnly = [
  {
    path: '/',
    element: <ProtectedRoute />,
    children: [
      { path: '/', element: <TablePage /> },
      { path: '/workers', element: <WorkerManagementPage /> },
      { path: '*', element: <Navigate to="." /> },
    ] satisfies RouteObject[],
  },
];
