function ArrowDownward(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={32}
      height={32}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M10 13l6 6 6-6" className="stroke-current" strokeWidth={2} />
    </svg>
  );
}

export { ArrowDownward };
