import { z } from 'zod';

export const usernameValidator = z
  .string()
  .min(6, { message: 'ユーザー名は6文字以上で入力してください' })
  .max(30, { message: 'ユーザー名は30文字以下で入力してください' })
  .regex(/^[a-zA-Z0-9\-_.#@]*$/, {
    message: 'ユーザー名は半角英数字と記号（-_.#@）のみ使用できます',
  });
export const passwordValidator = z
  .string()
  .regex(/^[a-zA-Z0-9\-_.#@]*$/, {
    message: 'パスワードは半角英数字と記号（-_.#@）のみ使用できます',
  })
  .regex(/^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d\-_.#@]*$/, {
    message: 'パスワードは少なくとも1つの文字と1つの数字を含めてください',
  })
  .min(8, { message: 'パスワードは8文字以上で入力してください' })
  .max(20, {
    message: 'パスワードは20文字以下で入力してください',
  });
