import primitiveColors from './primitive-colors.json';

export const semanticColors = {
  border: primitiveColors['cool-grey'][100],
  ring: primitiveColors['cool-grey'][700],
  background: primitiveColors['white'],
  alert: {
    DEFAULT: primitiveColors['red-vivid'][500],
    background: primitiveColors['red-vivid']['050'],
  },
  valid: {
    DEFAULT: primitiveColors['cyan-vivid'][500],
    background: primitiveColors['cyan-vivid']['050'],
  },
  muted: {
    DEFAULT: primitiveColors['cool-grey'][500],
  },
  text: {
    black: primitiveColors['cool-grey'][800],
    alert: primitiveColors['red-vivid'][500],
    muted: primitiveColors['cool-grey'][500],
  },
  primary: {
    500: primitiveColors['blue-vivid'][500],
  },
  dot: {
    added: '#faff00',
  },
};
