import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import { useAuth, Worker, WorkerCreate } from 'entrypoint';

import {
  getWorkers,
  getInactiveWorkers,
  updateWorkers,
  createWorkers,
} from '@/api/axios';

export const useWorkers = () => {
  const { isAuthenticated } = useAuth();

  return useQuery({
    queryKey: ['workerList'],
    queryFn: () => getWorkers(),
    enabled: isAuthenticated,
  });
};

export const useInactiveWorkers = () => {
  const { isAuthenticated } = useAuth();

  return useQuery({
    queryKey: ['inactiveWorkerList'],
    queryFn: () => getInactiveWorkers(),
    enabled: isAuthenticated,
  });
};

export const useUpdateWorkers = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (workersUpdateIn: Worker) => updateWorkers(workersUpdateIn),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['workerList']);
        queryClient.invalidateQueries(['inactiveWorkerList']);
      },
    },
  );
};

export const useCreateWorkers = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (workersCreate: WorkerCreate) => createWorkers(workersCreate),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['workerList']);
      },
    },
  );
};
