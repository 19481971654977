import { FC } from 'react';

import ArrowSmallLine from '../Icons/ArrowSmallLine';

type NavigationButtonProps = {
  text: string;
  onClick: () => void;
  state?: 'default' | 'selected';
};
export const NavigationButton: FC<NavigationButtonProps> = ({
  text,
  onClick,
  state = 'default',
}) => {
  return (
    <button
      className={`flex max-h-14 min-h-10 w-full items-center rounded stroke-black p-2 text-s transition-colors hover:bg-blue-vivid-050 hover:stroke-blue-vivid-400 ${
        state === 'selected' ? 'bg-blue-vivid-050 stroke-blue-vivid-400' : ''
      }`}
      onClick={onClick}
    >
      <div className="text-sm line-clamp-2 flex-grow overflow-hidden text-left">
        {text}
      </div>
      <ArrowSmallLine className="ml-2 flex-shrink-0" />
    </button>
  );
};
