// TODO: ここ名前変えないと既存顧客がv2に移行するときに認証落ちたりしそうなので考える
const storagePrefix = 'count_ai_';

export const tokenStorageHandlers = {
  getToken: () => {
    return JSON.parse(
      window.localStorage.getItem(`${storagePrefix}token`) as string,
    );
  },
  setToken: (token: string) => {
    window.localStorage.setItem(`${storagePrefix}token`, JSON.stringify(token));
  },
  clearToken: () => {
    window.localStorage.removeItem(`${storagePrefix}token`);
  },
};
