import {
  QueryClientProvider,
  QueryErrorResetBoundary,
} from '@tanstack/react-query';
import { AuthProvider } from 'entrypoint';
import { ErrorBoundary } from 'react-error-boundary';
import { BrowserRouter as Router } from 'react-router-dom';

import { queryClient } from '../api/tanstack-query';
import { Button } from '../components/Button';

type AppProviderProps = {
  children: React.ReactNode;
};

export const AppProvider = ({ children }: AppProviderProps) => {
  return (
    <QueryErrorResetBoundary>
      {({ reset }) => (
        <ErrorBoundary
          onReset={reset}
          fallbackRender={({ resetErrorBoundary }) => (
            <div
              className="flex h-screen w-screen flex-col items-center justify-center gap-4 text-text-alert"
              role="alert"
            >
              <h2 className="text-lg font-semibold">エラーが発生しました</h2>
              <Button onClick={() => resetErrorBoundary()}>再試行</Button>
            </div>
          )}
        >
          <AuthProvider>
            <QueryClientProvider client={queryClient}>
              <Router>{children}</Router>
              {/* <ReactQueryDevtools /> */}
            </QueryClientProvider>
          </AuthProvider>
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
};
