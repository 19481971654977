import { toast } from 'sonner';

import WorkerDialog from './WorkerDialog';

import { useCreateWorkers } from '@/hooks/api/useWorkers';

export const CreateWorkerDialog = () => {
  const createWorkers = useCreateWorkers();

  const handleSave = (name: string) => {
    const newWorker = {
      name: name,
      is_active: true,
    };

    createWorkers.mutate(newWorker, {
      onSuccess: () => {
        toast.success('作業者を追加しました');
      },
      onError: (e) => {
        console.error(e);
        toast.error('作業者の追加に失敗しました。');
      },
    });
  };

  return (
    <WorkerDialog
      triggerText="+ 新規追加"
      titleText="作業者新規追加"
      saveText="追加"
      colorVariant="primary"
      initialName=""
      onSave={handleSave}
    />
  );
};
