import {
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { EvidenceView, EvidenceViewUpdate } from 'entrypoint';
import { useSearchParams } from 'react-router-dom';

import { getViewById, getViews, updateView } from '@/api/axios';

export const useViewsQuery = (): UseQueryResult<EvidenceView[], unknown> =>
  useQuery({
    queryKey: ['views'],
    queryFn: () => getViews(),
  });

export const useViewQueryBySearchParams = (): UseQueryResult<
  EvidenceView,
  unknown
> => {
  const [searchParams] = useSearchParams();
  const viewId = searchParams.get('viewId') ?? ''; // TODO: [refactor] hooks化してもいいかも
  return useViewQueryById(viewId);
};

export const useViewQueryById = (
  id: string,
): UseQueryResult<EvidenceView, unknown> =>
  useQuery({
    queryKey: ['view', id],
    queryFn: () => getViewById(id),
    enabled: !!id,
  });

export const useUpdateView = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (viewUpdateIn: EvidenceViewUpdate) => updateView(viewUpdateIn),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['views']);
        queryClient.invalidateQueries(['view']);
      },
    },
  );
};
