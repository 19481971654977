import { Worker } from 'entrypoint';
import { toast } from 'sonner';

import { Button } from '@/components/Button';
import {
  Dialog,
  DialogContent,
  DialogClose,
  DialogTrigger,
  DialogHeader,
  DialogTitle,
} from '@/components/Dialog';
import { useUpdateWorkers } from '@/hooks/api/useWorkers';

interface WorkerDialogProps {
  worker: Worker;
}

export const ToggleWorkerStatusDialog = ({ worker }: WorkerDialogProps) => {
  const updateWorkers = useUpdateWorkers();

  const handleToggleStatus = () => {
    const updatedWorker = {
      id: worker.id,
      name: worker.name,
      is_active: !worker.is_active,
    };

    updateWorkers.mutate(updatedWorker, {
      onSuccess: () => {
        toast.success(
          `${worker.name}を${worker.is_active ? '無効化' : '有効化'}しました`,
        );
      },
      onError: (e) => {
        console.error(e);
        toast.error(
          `${worker.name}の${worker.is_active ? '無効化' : '有効化'}に失敗しました。`,
        );
      },
    });
  };

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button colorVariant="ghost">
          <div
            className="flex items-center justify-center gap-4"
            style={{
              fontWeight: 'normal',
              color: worker.is_active ? '#E12D39' : '#0967D2',
            }}
          >
            {worker.is_active ? '無効化' : '有効化'}
          </div>
        </Button>
      </DialogTrigger>
      <DialogContent style={{ maxWidth: '500px', maxHeight: '300px' }}>
        <div className="flex h-full flex-col space-y-8 p-6">
          <DialogHeader>
            <DialogTitle>
              作業者を
              {worker.is_active ? '無効化' : '有効化'}しますか？
            </DialogTitle>
          </DialogHeader>
          <div>
            <label htmlFor="worker-name" className="mb-1 block">
              表示名
            </label>
            <p className="font-medium">{worker.name}</p>
          </div>
          <div className="flex w-full space-x-4">
            <DialogClose asChild>
              <Button
                isFullWidth
                colorVariant="cancel"
                className="bg-cool-grey-100"
              >
                キャンセル
              </Button>
            </DialogClose>
            <Button
              isFullWidth
              colorVariant={worker.is_active ? 'danger' : 'primary'}
              onClick={handleToggleStatus}
            >
              {worker.is_active ? '無効化' : '有効化'}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
