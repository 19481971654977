import { QueryErrorResetBoundary } from '@tanstack/react-query';
import { useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useLocation } from 'react-router-dom';

import { Button } from '../../../components/Button';
import { ContentLayout } from '../../../components/Layout/ContentLayout';
import { PageLayout } from '../../../components/Layout/PageLayout';
import { Navigation } from '../../../components/Navigation';
import { WorkersTable } from '../components/WorkersTable';

import { useWorkers, useInactiveWorkers } from '@/hooks/api/useWorkers';

export const WorkerManagementPage = () => {
  const { data: activeWorkers } = useWorkers();
  const { data: inactiveWorkers } = useInactiveWorkers();
  const workers = [
    ...(Array.isArray(activeWorkers) ? activeWorkers : []),
    ...(Array.isArray(inactiveWorkers) ? inactiveWorkers : []),
  ];
  const location = useLocation();
  const { isSettingMenuOpen: isSettingMenuOpenFromState } =
    location.state || {};
  const [isSettingMenuOpen, setIsSettingMenuOpen] = useState(
    isSettingMenuOpenFromState || false,
  );

  return (
    <PageLayout>
      <Navigation
        isSettingMenuOpen={isSettingMenuOpen}
        setIsSettingMenuOpen={setIsSettingMenuOpen}
      />
      <ContentLayout>
        <div className="flex h-16 items-center justify-between">
          <h1 className="my-8 text-left text-xl font-bold">作業者一覧</h1>
        </div>

        <QueryErrorResetBoundary>
          {({ reset }) => (
            <ErrorBoundary
              onReset={reset}
              fallbackRender={({ resetErrorBoundary }) => (
                <div
                  className="mx-auto mt-8 w-fit gap-4 space-y-4 text-text-alert"
                  role="alert"
                >
                  <h2 className="text-lg font-semibold">
                    テーブルの読み込み中にエラーが発生しました
                  </h2>
                  <Button onClick={() => resetErrorBoundary()} isFullWidth>
                    再読み込み
                  </Button>
                </div>
              )}
            >
              {workers && <WorkersTable workers={workers} />}
            </ErrorBoundary>
          )}
        </QueryErrorResetBoundary>
      </ContentLayout>
    </PageLayout>
  );
};
