import { VariantProps, cva } from 'class-variance-authority';
import { ComponentPropsWithRef, forwardRef } from 'react';

import { Spinner } from '../Spinner/Spinner';

export const buttonVariants = cva(
  'inline-flex items-center justify-center rounded ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none',
  {
    variants: {
      colorVariant: {
        primary:
          'bg-primary-500 text-white hover:bg-blue-vivid-600 focus-visible:ring-primary-500',
        secondary:
          'bg-blue-vivid-050 text-primary-500 hover:bg-blue-vivid-200 focus-visible:ring-blue-vivid-200',
        alert: 'bg-alert text-white hover:bg-alert/90 focus-visible:ring-alert',
        // FIXME: ghostだけtextの色を与えていないため、上から上書きできるようになってる。他のtailwindMerge使ってもいいかも
        ghost:
          'bg-transparent hover:bg-cool-grey-100 focus-visible:ring-cool-grey-200',
        cancel:
          'bg-cool-grey-100 text-cool-grey-600 hover:bg-cool-grey-200 focus-visible:ring-cool-grey-200',
        danger:
          'bg-red-vivid-500	text-white hover:bg-red-vivid-600 focus-visible:ring-red-vivid-500',
      },
      size: {
        sm: 'px-3 py-[6px] text-xs font-bold',
        md: 'px-3 py-2 text-s font-bold',
        'square-sm': 'h-6 w-6 text-xs font-bold',
        'square-md': 'h-8 w-8 text-xs font-bold',
      },
      isFullWidth: {
        true: 'w-full',
        false: '',
      },
      isLoading: {
        true: 'opacity-50',
        false: '',
      },
    },
    compoundVariants: [
      {
        colorVariant: ['primary', 'secondary'],
        isLoading: false,
        className: 'disabled:bg-cool-grey-100 disabled:text-muted',
      },
      {
        colorVariant: 'ghost',
        isLoading: false,
        className: 'disabled:text-cool-grey-200',
      },
    ],
    defaultVariants: {
      colorVariant: 'primary',
      size: 'md',
      isFullWidth: false,
    },
  },
);

export interface ButtonProps
  extends ComponentPropsWithRef<'button'>,
    VariantProps<typeof buttonVariants> {}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      disabled = false,
      onClick,
      colorVariant = 'primary',
      size,
      isLoading = false,
      className = '',
      children,
      isFullWidth = false,
      ...props
    },
    ref,
  ) => {
    const isDisabled = disabled || (isLoading !== null && isLoading);
    return (
      <button
        ref={ref}
        disabled={isDisabled}
        onClick={onClick}
        className={buttonVariants({
          colorVariant: colorVariant,
          size,
          isFullWidth,
          isLoading,
          className,
        })}
        {...props}
      >
        {size === 'square-sm' || size === 'square-md' ? (
          isLoading ? (
            <Spinner
              size="sm"
              variant={colorVariant === 'primary' ? 'light' : 'primary'}
            />
          ) : (
            children
          )
        ) : isLoading ? (
          <>
            <div className="mr-2">
              <Spinner
                size="sm"
                variant={colorVariant === 'primary' ? 'light' : 'primary'}
              />
            </div>
            {children}
          </>
        ) : (
          children
        )}
      </button>
    );
  },
);

Button.displayName = 'Button';
