type UserIconProps = {
  /** apply fill-{color} class to change color*/
  className?: string;
};
const UserIcon = ({ className }: UserIconProps) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <mask
        id="mask0_1139_11546"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="32"
        height="32"
      >
        <circle cx="16" cy="16" r="16" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1139_11546)">
        <circle cx="16" cy="16" r="18" fill="#BAE3FF" />
        <circle cx="16" cy="12.5" r="5.5" fill="#47A3F3" />
        <path
          d="M16.5 20H15.5C14.1064 20 13.4096 20 12.8275 20.0989C9.89469 20.5972 7.5972 22.8947 7.0989 25.8275C7 26.4096 7 27.1064 7 28.5C7 29.8936 7 30.5904 7.0989 31.1725C7.5972 34.1053 9.89469 36.4028 12.8275 36.9011C13.4096 37 14.1064 37 15.5 37H16.5C17.8936 37 18.5904 37 19.1725 36.9011C22.1053 36.4028 24.4028 34.1053 24.9011 31.1725C25 30.5904 25 29.8936 25 28.5C25 27.1064 25 26.4096 24.9011 25.8275C24.4028 22.8947 22.1053 20.5972 19.1725 20.0989C18.5904 20 17.8936 20 16.5 20Z"
          fill="#47A3F3"
        />
      </g>
    </svg>
  );
};

export default UserIcon;
