import * as React from 'react';

import { useSidebar } from '@/context/SidebarContext';

const Table = React.forwardRef<
  HTMLTableElement,
  React.HTMLAttributes<HTMLTableElement>
>(({ ...props }, ref) => {
  const { sidebarWidth } = useSidebar();

  return (
    <div
      className="max-h-[570px] overflow-auto"
      style={{ maxWidth: `calc(100vw - ${sidebarWidth})` }}
    >
      <table ref={ref} className={'w-full text-l'} {...props} />
    </div>
  );
});
Table.displayName = 'Table';

const TableHeader = React.forwardRef<
  HTMLTableSectionElement,
  Omit<React.HTMLAttributes<HTMLTableSectionElement>, 'className'>
>(({ ...props }, ref) => (
  <thead
    ref={ref}
    className={'border-border font-bold [&_tr]:border-b'}
    {...props}
  />
));
TableHeader.displayName = 'TableHeader';

const TableBody = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ ...props }, ref) => <tbody ref={ref} {...props} />);
TableBody.displayName = 'TableBody';

const TableFooter = React.forwardRef<
  HTMLTableSectionElement,
  Omit<React.HTMLAttributes<HTMLTableSectionElement>, 'className'>
>(({ ...props }, ref) => (
  <tfoot ref={ref} className={'bg-white text-primary-500'} {...props} />
));
TableFooter.displayName = 'TableFooter';

const TableRow = React.forwardRef<
  HTMLTableRowElement,
  Omit<React.HTMLAttributes<HTMLTableRowElement>, 'className'> & {
    selected?: boolean; // selectedプロパティを追加
    isHeaderRow?: boolean; // isHeaderRowプロパティを追加
  }
>(({ onClick, selected, isHeaderRow, ...props }, ref) => (
  <tr
    ref={ref}
    onClick={onClick}
    className={`h-14 border-b border-border ${
      selected
        ? 'rounded outline outline-2 -outline-offset-2 outline-blue-vivid-200'
        : ''
    } ${
      !isHeaderRow
        ? `transition-colors hover:bg-blue-vivid-050 data-[state=selected]:bg-primary-500/20 ${
            onClick ? 'cursor-pointer' : ''
          } `
        : ''
    }
    `}
    {...props}
  />
));
TableRow.displayName = 'TableRow';

const TableHead = React.forwardRef<
  HTMLTableCellElement,
  React.ThHTMLAttributes<HTMLTableCellElement>
>(({ className, ...props }, ref) => (
  <th
    ref={ref}
    className={`sticky top-0 z-10 h-6 min-w-24 bg-white px-4 align-middle text-xs font-bold text-black ${className}`}
    {...props}
  />
));
TableHead.displayName = 'TableHead';

const TableCell = React.forwardRef<
  HTMLTableCellElement,
  React.TdHTMLAttributes<HTMLTableCellElement>
>(({ className, ...props }, ref) => (
  <td
    ref={ref}
    className={`px-2 text-center align-middle text-s ${className}`}
    {...props}
  />
));
TableCell.displayName = 'TableCell';

const TableCaption = React.forwardRef<
  HTMLTableCaptionElement,
  Omit<React.HTMLAttributes<HTMLTableCaptionElement>, 'className'>
>(({ ...props }, ref) => (
  <caption ref={ref} className={'mt-4 text-s text-text-muted'} {...props} />
));
TableCaption.displayName = 'TableCaption';

export {
  Table,
  TableHeader,
  TableBody,
  TableFooter,
  TableHead,
  TableRow,
  TableCell,
  TableCaption,
};
