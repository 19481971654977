import { Spinner } from './Spinner';

import appLogoSvg from '@/assets/app-logo.svg';

export const FullPageSpinner = () => {
  return (
    <div className="flex h-screen w-screen items-center justify-center">
      <div className="flex flex-col items-center">
        <img
          src={appLogoSvg}
          alt="StatHack CountAI"
          className="mb-3"
          width={240}
          height={33}
        />
        <p className="mb-7 text-s font-bold text-primary-500">読み込み中...</p>
        <Spinner size={'lg'} variant={'primary'} />
      </div>
    </div>
  );
};
