import {
  AxiosRequestConfig,
  Body_login_access_token_api_login_access_token_post,
  EvidenceResponse,
  EvidenceView,
  EvidenceViewUpdate,
  InspectionLogForCount,
  aspidaClient,
  Worker,
  WorkerCreate,
} from 'entrypoint';

export const login = (
  data: Body_login_access_token_api_login_access_token_post,
  config?: AxiosRequestConfig,
) =>
  aspidaClient.api.login.access_token.$post({
    body: data,
    config,
  });

export const getMe = (config?: AxiosRequestConfig) =>
  aspidaClient.api.users.me.$get({ config });

export const getOrganization = (config?: AxiosRequestConfig) =>
  aspidaClient.api.organizations.$get({
    config,
  });

export const getViews = (config?: AxiosRequestConfig) =>
  aspidaClient.api.evidence_views.$get({ config });

export const getViewById = async (
  id: EvidenceView['id'],
  config?: AxiosRequestConfig,
) => {
  const views = await aspidaClient.api.evidence_views.$get({
    config,
  });
  return views.find((view) => view.id === id);
};

export const getEvidences = async (
  viewId: EvidenceView['id'],
  startDate?: string,
  endDate?: string,
  searchText?: string,
  skip?: number,
  limit?: number,
  config?: AxiosRequestConfig,
) => {
  const view = await getViewById(viewId, config);
  if (view === undefined) {
    throw new Error('view is undefined');
  }
  return await aspidaClient.api.evidences.$get({
    config,
    query: {
      workflow_id: view.workflow_id,
      start_date: startDate,
      end_date: endDate,
      search_text: searchText,
      skip,
      limit,
    },
  });
};

export const getEvidenceDetailById = (
  id: EvidenceResponse['id'],
  config?: AxiosRequestConfig,
) => aspidaClient.api.evidences._evidence_id(id).$get({ config });

export const getWorkers = () => aspidaClient.api.workers.$get();

export const getInactiveWorkers = () =>
  aspidaClient.api.workers.inactive.$get();

export const updateWorkers = (data: Worker) =>
  aspidaClient.api.workers._worker_id(data.id).$put({
    body: data,
  });

export const createWorkers = (data: WorkerCreate) =>
  aspidaClient.api.workers.$post({
    body: data,
  });

export const updateView = (data: EvidenceViewUpdate) =>
  aspidaClient.api.evidence_views._evidence_view_id(data.id).$put({
    body: data,
  });

export const updateEvidence = (
  evidenceId: string,
  body: InspectionLogForCount[],
) => {
  aspidaClient.api.evidences._evidence_id(evidenceId).inspection_log.$put({
    body,
  });
};

export const deleteEvidence = (
  evidenceId: string,
  config?: AxiosRequestConfig,
) => aspidaClient.api.evidences._evidence_id(evidenceId).$delete({ config });
