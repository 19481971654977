import { toast } from 'sonner';

import WorkerDialog from './WorkerDialog';

import { useUpdateWorkers } from '@/hooks/api/useWorkers';

interface EditWorkerDialogProps {
  worker: {
    id: string;
    name: string;
    is_active: boolean;
  };
}

export const EditWorkerDialog = ({ worker }: EditWorkerDialogProps) => {
  const updateWorkers = useUpdateWorkers();

  const handleSave = (name: string) => {
    const updatedWorker = {
      id: worker.id,
      name: name,
      is_active: worker.is_active,
    };

    updateWorkers.mutate(updatedWorker, {
      onSuccess: () => {
        toast.success('作業者を更新しました');
      },
      onError: (e) => {
        console.error(e);
        toast.error('作業者の更新に失敗しました。');
      },
    });
  };

  return (
    <WorkerDialog
      triggerText="編集"
      titleText="作業者編集"
      saveText="保存"
      colorVariant="ghost"
      initialName={worker.name}
      onSave={handleSave}
    />
  );
};
