import { ComponentProps, FC } from 'react';

import UserIcon from '../Icons/UserIcon';

type ProfileProps = {
  iconSrc?: string;
  name: string;
  id: string;
} & Omit<ComponentProps<'button'>, 'children'>;
export const Profile: FC<ProfileProps> = ({ iconSrc = '', name, id }) => {
  return (
    <div className="grid w-full gap-1 rounded border border-border p-3">
      <div className="flex items-center gap-3">
        {iconSrc ? (
          <img src={iconSrc} alt="" className="h-10 w-10 rounded-full" />
        ) : (
          <UserIcon />
        )}
        <p>{name}</p>
      </div>
      <p className="text-xs text-cool-grey-400">{id}</p>
    </div>
  );
};
