import axiosClient from '@aspida/axios';
import axios from 'axios';

import api from '../aspida/$api';
import { tokenStorageHandlers } from '../libs/storage';

export let aspidaClient = api(axiosClient(axios));

export const initAspidaClient = (apiBaseURL: string) => {
  aspidaClient = api(
    axiosClient(axios, {
      baseURL: apiBaseURL,
    }),
  );
};

axios.interceptors.request.use((config) => {
  const token = tokenStorageHandlers.getToken();
  config.headers = config.headers ?? {};
  config.headers['Authorization'] = `Bearer ${token}`;
  return config;
});

export const authAxios = axios;
