import { version } from '../../../../package.json';

import { cn } from '@/lib/utils';

function AppVersion({
  className,
  ...props
}: React.HTMLAttributes<HTMLParagraphElement>) {
  return (
    <p className={cn('text-xs text-cool-grey-200', className)} {...props}>
      バージョン {version}
    </p>
  );
}

export { AppVersion };
