import React, { createContext, useContext, useState, useCallback } from 'react';

import { aspidaClient } from '../api';
import { User } from '../aspida/@types';
import { tokenStorageHandlers } from '../libs/storage';

type Result = {
  success: boolean;
  message: string;
};

type AuthContext = {
  verifyAccessToken: () => Promise<void>;
  isAuthenticated: boolean;
  setIsAuthenticated: (isAuthenticated: boolean) => void;
  currentUser: User | null;
  setCurrentUser: (currentUser: User | null) => void;
  signIn: (username: string, password: string) => Promise<Result>;
  signOut: () => Promise<Result>;
  isLoading: boolean;
  isInitialized: boolean;
};

const AuthContext = createContext<AuthContext | undefined>(undefined);

export const useAuth = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within AuthContext');
  }

  return context;
};

type AuthProviderProps = {
  children: React.ReactNode;
};

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);

  const verifyAccessToken = useCallback(async () => {
    setIsInitialized(false);
    const token = tokenStorageHandlers.getToken();

    // LocalStorageにtokenがなければログアウト
    if (!token) {
      setIsAuthenticated(false);
      setIsInitialized(true);
      return;
    }
    try {
      const decodedJwt = JSON.parse(atob(token.split('.')[1]));
      // access tokenの有効期限が切れていたらログアウト
      if (decodedJwt.exp * 1000 < Date.now()) {
        tokenStorageHandlers.clearToken();
        setIsAuthenticated(false);
        setIsInitialized(true);
        return;
      }

      // currentUserの情報がなければ取得してセット
      if (!currentUser) {
        const user = await aspidaClient.api.users.me.$get();
        setCurrentUser(user);
      }
      setIsAuthenticated(true);
      setIsInitialized(true);
    } catch (error) {
      tokenStorageHandlers.clearToken();
      setIsAuthenticated(false);
      setIsInitialized(true);
      return;
    }
  }, [currentUser, setIsAuthenticated, setCurrentUser]);

  const signIn = useCallback(
    async (username: string, password: string) => {
      try {
        setIsLoading(true);
        const result = await aspidaClient.api.login.access_token.$post({
          body: { username, password: password },
        });
        tokenStorageHandlers.setToken(result.access_token);
        const user = await aspidaClient.api.users.me.$get();
        setCurrentUser(user);
        setIsAuthenticated(true);
        return { success: true, message: '' };
      } catch (error) {
        const errorName: string = (error as Error).name;
        let errorMessage: string;
        if (errorName === 'NotAuthorizedException') {
          errorMessage = 'メールアドレスまたはパスワードが正しくありません';
        } else if (errorName === 'UserNotFoundException') {
          errorMessage = 'このメールアドレスは登録されていません';
        } else {
          errorMessage = 'ログインに失敗しました';
        }
        return {
          success: false,
          message: errorMessage,
        };
      } finally {
        setIsLoading(false);
      }
    },
    [setCurrentUser, setIsAuthenticated],
  );

  const signOut = useCallback(async () => {
    try {
      setIsLoading(true);
      tokenStorageHandlers.clearToken();
      setIsAuthenticated(false);
      return { success: true, message: '' };
    } catch (error) {
      return {
        success: false,
        message: 'ログアウトに失敗しました。',
      };
    } finally {
      setIsLoading(false);
    }
  }, [setIsAuthenticated]);

  const value: AuthContext = {
    verifyAccessToken,
    isAuthenticated,
    setIsAuthenticated,
    currentUser,
    setCurrentUser,
    signIn,
    signOut,
    isLoading,
    isInitialized,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
