import { ReactNode } from 'react';

import { Toaster } from '../Toaster';

import { SidebarProvider } from '@/context/SidebarContext';

type PageLayoutProps = {
  children: ReactNode;
};

export const PageLayout = ({ children }: PageLayoutProps) => {
  return (
    <SidebarProvider>
      <div className="flex min-h-screen w-full bg-background">
        {children}
        <Toaster richColors />
      </div>
    </SidebarProvider>
  );
};
