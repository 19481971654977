import { useQuery } from '@tanstack/react-query';
import { useAuth } from 'entrypoint';

import { getOrganization } from '@/api/axios';

export const useOrganizationQuery = () => {
  const { currentUser, isAuthenticated } = useAuth();

  return useQuery({
    queryKey: ['organization', currentUser?.id],
    queryFn: () => getOrganization(),
    enabled: isAuthenticated,
  });
};
