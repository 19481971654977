import { useAuth } from 'entrypoint';
import { useEffect } from 'react';
import { useLocation, useRoutes } from 'react-router-dom';

import { routesForAuthenticatedOnly } from './authenticated';
import { routesForNotAuthenticatedOnly } from './non-authenticated';

import { sentrySetUser } from '@/config/sentry';

export const AppRoutes = () => {
  // TODO: authとまとめて、ログイン済みかどうかの判定を行う
  const { isAuthenticated, verifyAccessToken, currentUser } = useAuth();
  const location = useLocation();

  useEffect(() => {
    verifyAccessToken();
  }, [location, verifyAccessToken]);

  useEffect(() => {
    if (!currentUser) {
      return;
    }
    sentrySetUser(currentUser);
  }, [currentUser]);

  const routes = [
    ...(!isAuthenticated ? routesForNotAuthenticatedOnly : []),
    ...routesForAuthenticatedOnly,
  ];

  const element = useRoutes([...routes]);
  return <>{element}</>;
};
