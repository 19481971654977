import * as ChannelService from '@channel.io/channel-web-sdk-loader';
import { initAspidaClient } from 'entrypoint/src/api';
import React from 'react';
import ReactDOM from 'react-dom/client';
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';
import './styles/index.css';

import App from './App';
import { API_URL, CHANNEL_TALK_PLUGIN_KEY, GA_TRACKING_ID } from './config';
import { initSentry } from './config/sentry';

const tagManagerArgs = {
  gtmId: 'GTM-MX7W8XRZ',
};

TagManager.initialize(tagManagerArgs);

const prepare = async () => {
  initSentry();
  initAspidaClient(API_URL);
  ChannelService.loadScript();
  ChannelService.boot({
    pluginKey: CHANNEL_TALK_PLUGIN_KEY,
  });
  if (GA_TRACKING_ID) {
    ReactGA.initialize(GA_TRACKING_ID);
  }
  return Promise.resolve();
};
prepare().then(() => {
  ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
  );
});
