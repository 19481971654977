import type { AspidaClient, BasicHeaders } from 'aspida';
import { dataToURLString } from 'aspida';

import type { Methods as Methods_11q6j1f } from './api/admin-dashboard/evidence-views/from-workflow';
import type { Methods as Methods_r0ql5i } from './api/admin-dashboard/workflows';
import type { Methods as Methods_8e0c9d } from './api/admin-dashboard/workflows/trial';
import type { Methods as Methods_x0t5d1 } from './api/ai/count';
import type { Methods as Methods_3jz4dk } from './api/configs/supported-models';
import type { Methods as Methods_1nrgp6d } from './api/evidence-views';
import type { Methods as Methods_1w24arf } from './api/evidence-views/_evidence_view_id@string';
import type { Methods as Methods_5watpj } from './api/evidences';
import type { Methods as Methods_1vtr1dp } from './api/evidences/_evidence_id@string';
import type { Methods as Methods_z6vdzv } from './api/evidences/_evidence_id@string/inspection-log';
import type { Methods as Methods_1d8skzz } from './api/login/access-token';
import type { Methods as Methods_17lxqgl } from './api/login/test-token';
import type { Methods as Methods_10jk4ud } from './api/organizations';
import type { Methods as Methods_ae6l5j } from './api/ping';
import type { Methods as Methods_80a9vt } from './api/users';
import type { Methods as Methods_1xj93o1 } from './api/users/admin';
import type { Methods as Methods_3t4nqs } from './api/users/device';
import type { Methods as Methods_kc8508 } from './api/users/me';
import type { Methods as Methods_1pc2axa } from './api/workers';
import type { Methods as Methods_vcdixj } from './api/workers/_worker_id@string';
import type { Methods as Methods_sapn7u } from './api/workers/inactive';
import type { Methods as Methods_1qhrqnv } from './api/workflows';

const api = <T>({ baseURL, fetch }: AspidaClient<T>) => {
  const prefix = (baseURL === undefined ? '' : baseURL).replace(/\/$/, '');
  const PATH0 = '/api/admin-dashboard/evidence-views/from-workflow';
  const PATH1 = '/api/admin-dashboard/workflows';
  const PATH2 = '/api/admin-dashboard/workflows/trial';
  const PATH3 = '/api/ai/count';
  const PATH4 = '/api/configs/supported-models';
  const PATH5 = '/api/evidence-views';
  const PATH6 = '/api/evidences';
  const PATH7 = '/inspection-log';
  const PATH8 = '/api/login/access-token';
  const PATH9 = '/api/login/test-token';
  const PATH10 = '/api/organizations';
  const PATH11 = '/api/ping';
  const PATH12 = '/api/users';
  const PATH13 = '/api/users/admin';
  const PATH14 = '/api/users/device';
  const PATH15 = '/api/users/me';
  const PATH16 = '/api/workers';
  const PATH17 = '/api/workers/inactive';
  const PATH18 = '/api/workflows';
  const GET = 'GET';
  const POST = 'POST';
  const PUT = 'PUT';
  const DELETE = 'DELETE';

  return {
    api: {
      admin_dashboard: {
        evidence_views: {
          from_workflow: {
            /**
             * Workflow IDからEvidence Viewを作成する
             * @returns Successful Response
             */
            post: (option: {
              body: Methods_11q6j1f['post']['reqBody'];
              config?: T | undefined;
            }) =>
              fetch<
                Methods_11q6j1f['post']['resBody'],
                BasicHeaders,
                Methods_11q6j1f['post']['status']
              >(prefix, PATH0, POST, option).json(),
            /**
             * Workflow IDからEvidence Viewを作成する
             * @returns Successful Response
             */
            $post: (option: {
              body: Methods_11q6j1f['post']['reqBody'];
              config?: T | undefined;
            }) =>
              fetch<
                Methods_11q6j1f['post']['resBody'],
                BasicHeaders,
                Methods_11q6j1f['post']['status']
              >(prefix, PATH0, POST, option)
                .json()
                .then((r) => r.body),
            $path: () => `${prefix}${PATH0}`,
          },
        },
        workflows: {
          trial: {
            /**
             * Trial Workflowを作成する
             */
            post: (option: {
              body: Methods_8e0c9d['post']['reqBody'];
              config?: T | undefined;
            }) =>
              fetch<void, BasicHeaders, Methods_8e0c9d['post']['status']>(
                prefix,
                PATH2,
                POST,
                option,
              ).send(),
            /**
             * Trial Workflowを作成する
             */
            $post: (option: {
              body: Methods_8e0c9d['post']['reqBody'];
              config?: T | undefined;
            }) =>
              fetch<void, BasicHeaders, Methods_8e0c9d['post']['status']>(
                prefix,
                PATH2,
                POST,
                option,
              )
                .send()
                .then((r) => r.body),
            $path: () => `${prefix}${PATH2}`,
          },
          /**
           * 組織に所属する全てのWorkflowを取得する
           * @returns Successful Response
           */
          get: (option: {
            query: Methods_r0ql5i['get']['query'];
            config?: T | undefined;
          }) =>
            fetch<
              Methods_r0ql5i['get']['resBody'],
              BasicHeaders,
              Methods_r0ql5i['get']['status']
            >(prefix, PATH1, GET, option).json(),
          /**
           * 組織に所属する全てのWorkflowを取得する
           * @returns Successful Response
           */
          $get: (option: {
            query: Methods_r0ql5i['get']['query'];
            config?: T | undefined;
          }) =>
            fetch<
              Methods_r0ql5i['get']['resBody'],
              BasicHeaders,
              Methods_r0ql5i['get']['status']
            >(prefix, PATH1, GET, option)
              .json()
              .then((r) => r.body),
          $path: (
            option?:
              | {
                  method?: 'get' | undefined;
                  query: Methods_r0ql5i['get']['query'];
                }
              | undefined,
          ) =>
            `${prefix}${PATH1}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
        },
      },
      ai: {
        count: {
          /**
           * @returns Successful Response
           */
          post: (option: {
            body: Methods_x0t5d1['post']['reqBody'];
            query?: Methods_x0t5d1['post']['query'] | undefined;
            config?: T | undefined;
          }) =>
            fetch<
              Methods_x0t5d1['post']['resBody'],
              BasicHeaders,
              Methods_x0t5d1['post']['status']
            >(prefix, PATH3, POST, option, 'FormData').json(),
          /**
           * @returns Successful Response
           */
          $post: (option: {
            body: Methods_x0t5d1['post']['reqBody'];
            query?: Methods_x0t5d1['post']['query'] | undefined;
            config?: T | undefined;
          }) =>
            fetch<
              Methods_x0t5d1['post']['resBody'],
              BasicHeaders,
              Methods_x0t5d1['post']['status']
            >(prefix, PATH3, POST, option, 'FormData')
              .json()
              .then((r) => r.body),
          $path: (
            option?:
              | { method: 'post'; query: Methods_x0t5d1['post']['query'] }
              | undefined,
          ) =>
            `${prefix}${PATH3}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
        },
      },
      configs: {
        supported_models: {
          /**
           * Get supported models
           */
          get: (option?: { config?: T | undefined } | undefined) =>
            fetch<void, BasicHeaders, Methods_3jz4dk['get']['status']>(
              prefix,
              PATH4,
              GET,
              option,
            ).send(),
          /**
           * Get supported models
           */
          $get: (option?: { config?: T | undefined } | undefined) =>
            fetch<void, BasicHeaders, Methods_3jz4dk['get']['status']>(
              prefix,
              PATH4,
              GET,
              option,
            )
              .send()
              .then((r) => r.body),
          $path: () => `${prefix}${PATH4}`,
        },
      },
      evidence_views: {
        _evidence_view_id: (val2: string) => {
          const prefix2 = `${PATH5}/${val2}`;

          return {
            /**
             * Evidence Viewを更新する
             */
            put: (option: {
              body: Methods_1w24arf['put']['reqBody'];
              config?: T | undefined;
            }) =>
              fetch<void, BasicHeaders, Methods_1w24arf['put']['status']>(
                prefix,
                prefix2,
                PUT,
                option,
              ).send(),
            /**
             * Evidence Viewを更新する
             */
            $put: (option: {
              body: Methods_1w24arf['put']['reqBody'];
              config?: T | undefined;
            }) =>
              fetch<void, BasicHeaders, Methods_1w24arf['put']['status']>(
                prefix,
                prefix2,
                PUT,
                option,
              )
                .send()
                .then((r) => r.body),
            $path: () => `${prefix}${prefix2}`,
          };
        },
        /**
         * 組織に所属する全てのEvidence Viewを取得する
         * @returns Successful Response
         */
        get: (option?: { config?: T | undefined } | undefined) =>
          fetch<
            Methods_1nrgp6d['get']['resBody'],
            BasicHeaders,
            Methods_1nrgp6d['get']['status']
          >(prefix, PATH5, GET, option).json(),
        /**
         * 組織に所属する全てのEvidence Viewを取得する
         * @returns Successful Response
         */
        $get: (option?: { config?: T | undefined } | undefined) =>
          fetch<
            Methods_1nrgp6d['get']['resBody'],
            BasicHeaders,
            Methods_1nrgp6d['get']['status']
          >(prefix, PATH5, GET, option)
            .json()
            .then((r) => r.body),
        $path: () => `${prefix}${PATH5}`,
      },
      evidences: {
        _evidence_id: (val2: string) => {
          const prefix2 = `${PATH6}/${val2}`;

          return {
            inspection_log: {
              /**
               * Update evidence by ID.
               * @returns Successful Response
               */
              put: (option: {
                body: Methods_z6vdzv['put']['reqBody'];
                config?: T | undefined;
              }) =>
                fetch<
                  Methods_z6vdzv['put']['resBody'],
                  BasicHeaders,
                  Methods_z6vdzv['put']['status']
                >(prefix, `${prefix2}${PATH7}`, PUT, option).json(),
              /**
               * Update evidence by ID.
               * @returns Successful Response
               */
              $put: (option: {
                body: Methods_z6vdzv['put']['reqBody'];
                config?: T | undefined;
              }) =>
                fetch<
                  Methods_z6vdzv['put']['resBody'],
                  BasicHeaders,
                  Methods_z6vdzv['put']['status']
                >(prefix, `${prefix2}${PATH7}`, PUT, option)
                  .json()
                  .then((r) => r.body),
              $path: () => `${prefix}${prefix2}${PATH7}`,
            },
            /**
             * Get evidence detail by ID.
             * @returns Successful Response
             */
            get: (option?: { config?: T | undefined } | undefined) =>
              fetch<
                Methods_1vtr1dp['get']['resBody'],
                BasicHeaders,
                Methods_1vtr1dp['get']['status']
              >(prefix, prefix2, GET, option).json(),
            /**
             * Get evidence detail by ID.
             * @returns Successful Response
             */
            $get: (option?: { config?: T | undefined } | undefined) =>
              fetch<
                Methods_1vtr1dp['get']['resBody'],
                BasicHeaders,
                Methods_1vtr1dp['get']['status']
              >(prefix, prefix2, GET, option)
                .json()
                .then((r) => r.body),
            /**
             * Update evidence by ID.
             * @returns Successful Response
             */
            put: (option: {
              body: Methods_1vtr1dp['put']['reqBody'];
              config?: T | undefined;
            }) =>
              fetch<
                Methods_1vtr1dp['put']['resBody'],
                BasicHeaders,
                Methods_1vtr1dp['put']['status']
              >(prefix, prefix2, PUT, option).json(),
            /**
             * Update evidence by ID.
             * @returns Successful Response
             */
            $put: (option: {
              body: Methods_1vtr1dp['put']['reqBody'];
              config?: T | undefined;
            }) =>
              fetch<
                Methods_1vtr1dp['put']['resBody'],
                BasicHeaders,
                Methods_1vtr1dp['put']['status']
              >(prefix, prefix2, PUT, option)
                .json()
                .then((r) => r.body),
            /**
             * Soft delete evidence by setting is_active to false.
             * @returns Successful Response
             */
            delete: (option?: { config?: T | undefined } | undefined) =>
              fetch<
                Methods_1vtr1dp['delete']['resBody'],
                BasicHeaders,
                Methods_1vtr1dp['delete']['status']
              >(prefix, prefix2, DELETE, option).json(),
            /**
             * Soft delete evidence by setting is_active to false.
             * @returns Successful Response
             */
            $delete: (option?: { config?: T | undefined } | undefined) =>
              fetch<
                Methods_1vtr1dp['delete']['resBody'],
                BasicHeaders,
                Methods_1vtr1dp['delete']['status']
              >(prefix, prefix2, DELETE, option)
                .json()
                .then((r) => r.body),
            $path: () => `${prefix}${prefix2}`,
          };
        },
        /**
         * Create a new evidence.
         */
        post: (option: {
          body: Methods_5watpj['post']['reqBody'];
          config?: T | undefined;
        }) =>
          fetch<void, BasicHeaders, Methods_5watpj['post']['status']>(
            prefix,
            PATH6,
            POST,
            option,
            'FormData',
          ).send(),
        /**
         * Create a new evidence.
         */
        $post: (option: {
          body: Methods_5watpj['post']['reqBody'];
          config?: T | undefined;
        }) =>
          fetch<void, BasicHeaders, Methods_5watpj['post']['status']>(
            prefix,
            PATH6,
            POST,
            option,
            'FormData',
          )
            .send()
            .then((r) => r.body),
        /**
         * Get evidences in organization.
         * @returns Successful Response
         */
        get: (option: {
          query: Methods_5watpj['get']['query'];
          config?: T | undefined;
        }) =>
          fetch<
            Methods_5watpj['get']['resBody'],
            BasicHeaders,
            Methods_5watpj['get']['status']
          >(prefix, PATH6, GET, option).json(),
        /**
         * Get evidences in organization.
         * @returns Successful Response
         */
        $get: (option: {
          query: Methods_5watpj['get']['query'];
          config?: T | undefined;
        }) =>
          fetch<
            Methods_5watpj['get']['resBody'],
            BasicHeaders,
            Methods_5watpj['get']['status']
          >(prefix, PATH6, GET, option)
            .json()
            .then((r) => r.body),
        $path: (
          option?:
            | {
                method?: 'get' | undefined;
                query: Methods_5watpj['get']['query'];
              }
            | undefined,
        ) =>
          `${prefix}${PATH6}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
      },
      login: {
        access_token: {
          /**
           * OAuth2 compatible token login, get an access token for future requests
           * @returns Successful Response
           */
          post: (option: {
            body: Methods_1d8skzz['post']['reqBody'];
            config?: T | undefined;
          }) =>
            fetch<
              Methods_1d8skzz['post']['resBody'],
              BasicHeaders,
              Methods_1d8skzz['post']['status']
            >(prefix, PATH8, POST, option, 'URLSearchParams').json(),
          /**
           * OAuth2 compatible token login, get an access token for future requests
           * @returns Successful Response
           */
          $post: (option: {
            body: Methods_1d8skzz['post']['reqBody'];
            config?: T | undefined;
          }) =>
            fetch<
              Methods_1d8skzz['post']['resBody'],
              BasicHeaders,
              Methods_1d8skzz['post']['status']
            >(prefix, PATH8, POST, option, 'URLSearchParams')
              .json()
              .then((r) => r.body),
          $path: () => `${prefix}${PATH8}`,
        },
        test_token: {
          /**
           * Test access token
           * @returns Successful Response
           */
          post: (option?: { config?: T | undefined } | undefined) =>
            fetch<
              Methods_17lxqgl['post']['resBody'],
              BasicHeaders,
              Methods_17lxqgl['post']['status']
            >(prefix, PATH9, POST, option).json(),
          /**
           * Test access token
           * @returns Successful Response
           */
          $post: (option?: { config?: T | undefined } | undefined) =>
            fetch<
              Methods_17lxqgl['post']['resBody'],
              BasicHeaders,
              Methods_17lxqgl['post']['status']
            >(prefix, PATH9, POST, option)
              .json()
              .then((r) => r.body),
          $path: () => `${prefix}${PATH9}`,
        },
      },
      organizations: {
        /**
         * @returns Successful Response
         */
        get: (option?: { config?: T | undefined } | undefined) =>
          fetch<
            Methods_10jk4ud['get']['resBody'],
            BasicHeaders,
            Methods_10jk4ud['get']['status']
          >(prefix, PATH10, GET, option).json(),
        /**
         * @returns Successful Response
         */
        $get: (option?: { config?: T | undefined } | undefined) =>
          fetch<
            Methods_10jk4ud['get']['resBody'],
            BasicHeaders,
            Methods_10jk4ud['get']['status']
          >(prefix, PATH10, GET, option)
            .json()
            .then((r) => r.body),
        $path: () => `${prefix}${PATH10}`,
      },
      ping: {
        post: (option?: { config?: T | undefined } | undefined) =>
          fetch<void, BasicHeaders, Methods_ae6l5j['post']['status']>(
            prefix,
            PATH11,
            POST,
            option,
          ).send(),
        $post: (option?: { config?: T | undefined } | undefined) =>
          fetch<void, BasicHeaders, Methods_ae6l5j['post']['status']>(
            prefix,
            PATH11,
            POST,
            option,
          )
            .send()
            .then((r) => r.body),
        $path: () => `${prefix}${PATH11}`,
      },
      users: {
        admin: {
          /**
           * 管理者ユーザの追加
           * @returns Successful Response
           */
          post: (option: {
            body: Methods_1xj93o1['post']['reqBody'];
            config?: T | undefined;
          }) =>
            fetch<
              Methods_1xj93o1['post']['resBody'],
              BasicHeaders,
              Methods_1xj93o1['post']['status']
            >(prefix, PATH13, POST, option).json(),
          /**
           * 管理者ユーザの追加
           * @returns Successful Response
           */
          $post: (option: {
            body: Methods_1xj93o1['post']['reqBody'];
            config?: T | undefined;
          }) =>
            fetch<
              Methods_1xj93o1['post']['resBody'],
              BasicHeaders,
              Methods_1xj93o1['post']['status']
            >(prefix, PATH13, POST, option)
              .json()
              .then((r) => r.body),
          $path: () => `${prefix}${PATH13}`,
        },
        device: {
          /**
           * デバイスユーザの追加
           * @returns Successful Response
           */
          post: (option: {
            body: Methods_3t4nqs['post']['reqBody'];
            config?: T | undefined;
          }) =>
            fetch<
              Methods_3t4nqs['post']['resBody'],
              BasicHeaders,
              Methods_3t4nqs['post']['status']
            >(prefix, PATH14, POST, option).json(),
          /**
           * デバイスユーザの追加
           * @returns Successful Response
           */
          $post: (option: {
            body: Methods_3t4nqs['post']['reqBody'];
            config?: T | undefined;
          }) =>
            fetch<
              Methods_3t4nqs['post']['resBody'],
              BasicHeaders,
              Methods_3t4nqs['post']['status']
            >(prefix, PATH14, POST, option)
              .json()
              .then((r) => r.body),
          $path: () => `${prefix}${PATH14}`,
        },
        me: {
          /**
           * Get current user.
           * @returns Successful Response
           */
          get: (option?: { config?: T | undefined } | undefined) =>
            fetch<
              Methods_kc8508['get']['resBody'],
              BasicHeaders,
              Methods_kc8508['get']['status']
            >(prefix, PATH15, GET, option).json(),
          /**
           * Get current user.
           * @returns Successful Response
           */
          $get: (option?: { config?: T | undefined } | undefined) =>
            fetch<
              Methods_kc8508['get']['resBody'],
              BasicHeaders,
              Methods_kc8508['get']['status']
            >(prefix, PATH15, GET, option)
              .json()
              .then((r) => r.body),
          $path: () => `${prefix}${PATH15}`,
        },
        /**
         * Retrieve users.
         * @returns Successful Response
         */
        get: (
          option?:
            | {
                query?: Methods_80a9vt['get']['query'] | undefined;
                config?: T | undefined;
              }
            | undefined,
        ) =>
          fetch<
            Methods_80a9vt['get']['resBody'],
            BasicHeaders,
            Methods_80a9vt['get']['status']
          >(prefix, PATH12, GET, option).json(),
        /**
         * Retrieve users.
         * @returns Successful Response
         */
        $get: (
          option?:
            | {
                query?: Methods_80a9vt['get']['query'] | undefined;
                config?: T | undefined;
              }
            | undefined,
        ) =>
          fetch<
            Methods_80a9vt['get']['resBody'],
            BasicHeaders,
            Methods_80a9vt['get']['status']
          >(prefix, PATH12, GET, option)
            .json()
            .then((r) => r.body),
        $path: (
          option?:
            | {
                method?: 'get' | undefined;
                query: Methods_80a9vt['get']['query'];
              }
            | undefined,
        ) =>
          `${prefix}${PATH12}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
      },
      workers: {
        _worker_id: (val2: string) => {
          const prefix2 = `${PATH16}/${val2}`;

          return {
            /**
             * Workerを更新する
             * @returns Successful Response
             */
            put: (option: {
              body: Methods_vcdixj['put']['reqBody'];
              config?: T | undefined;
            }) =>
              fetch<
                Methods_vcdixj['put']['resBody'],
                BasicHeaders,
                Methods_vcdixj['put']['status']
              >(prefix, prefix2, PUT, option).json(),
            /**
             * Workerを更新する
             * @returns Successful Response
             */
            $put: (option: {
              body: Methods_vcdixj['put']['reqBody'];
              config?: T | undefined;
            }) =>
              fetch<
                Methods_vcdixj['put']['resBody'],
                BasicHeaders,
                Methods_vcdixj['put']['status']
              >(prefix, prefix2, PUT, option)
                .json()
                .then((r) => r.body),
            $path: () => `${prefix}${prefix2}`,
          };
        },
        inactive: {
          /**
           * 組織に所属する全ての非アクティブなWorkerを取得する
           * @returns Successful Response
           */
          get: (option?: { config?: T | undefined } | undefined) =>
            fetch<
              Methods_sapn7u['get']['resBody'],
              BasicHeaders,
              Methods_sapn7u['get']['status']
            >(prefix, PATH17, GET, option).json(),
          /**
           * 組織に所属する全ての非アクティブなWorkerを取得する
           * @returns Successful Response
           */
          $get: (option?: { config?: T | undefined } | undefined) =>
            fetch<
              Methods_sapn7u['get']['resBody'],
              BasicHeaders,
              Methods_sapn7u['get']['status']
            >(prefix, PATH17, GET, option)
              .json()
              .then((r) => r.body),
          $path: () => `${prefix}${PATH17}`,
        },
        /**
         * 組織に所属する全てのアクティブなWorkerを取得する
         * @returns Successful Response
         */
        get: (option?: { config?: T | undefined } | undefined) =>
          fetch<
            Methods_1pc2axa['get']['resBody'],
            BasicHeaders,
            Methods_1pc2axa['get']['status']
          >(prefix, PATH16, GET, option).json(),
        /**
         * 組織に所属する全てのアクティブなWorkerを取得する
         * @returns Successful Response
         */
        $get: (option?: { config?: T | undefined } | undefined) =>
          fetch<
            Methods_1pc2axa['get']['resBody'],
            BasicHeaders,
            Methods_1pc2axa['get']['status']
          >(prefix, PATH16, GET, option)
            .json()
            .then((r) => r.body),
        /**
         * Workerを追加する
         * @returns Successful Response
         */
        post: (option: {
          body: Methods_1pc2axa['post']['reqBody'];
          config?: T | undefined;
        }) =>
          fetch<
            Methods_1pc2axa['post']['resBody'],
            BasicHeaders,
            Methods_1pc2axa['post']['status']
          >(prefix, PATH16, POST, option).json(),
        /**
         * Workerを追加する
         * @returns Successful Response
         */
        $post: (option: {
          body: Methods_1pc2axa['post']['reqBody'];
          config?: T | undefined;
        }) =>
          fetch<
            Methods_1pc2axa['post']['resBody'],
            BasicHeaders,
            Methods_1pc2axa['post']['status']
          >(prefix, PATH16, POST, option)
            .json()
            .then((r) => r.body),
        $path: () => `${prefix}${PATH16}`,
      },
      workflows: {
        /**
         * 組織に所属する全てのWorkflowを取得する
         * @returns Successful Response
         */
        get: (option?: { config?: T | undefined } | undefined) =>
          fetch<
            Methods_1qhrqnv['get']['resBody'],
            BasicHeaders,
            Methods_1qhrqnv['get']['status']
          >(prefix, PATH18, GET, option).json(),
        /**
         * 組織に所属する全てのWorkflowを取得する
         * @returns Successful Response
         */
        $get: (option?: { config?: T | undefined } | undefined) =>
          fetch<
            Methods_1qhrqnv['get']['resBody'],
            BasicHeaders,
            Methods_1qhrqnv['get']['status']
          >(prefix, PATH18, GET, option)
            .json()
            .then((r) => r.body),
        $path: () => `${prefix}${PATH18}`,
      },
    },
  };
};

export type ApiInstance = ReturnType<typeof api>;
export default api;
