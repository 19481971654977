export const API_URL = import.meta.env.VITE_API_URL;

import PrimitiveColorJson from '../styles/primitive-colors.json';
import { semanticColors } from '../styles/semanticColors';

export const ENVIRONMENT = import.meta.env.VITE_ENVIRONMENT;
export const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN;
export const CHANNEL_TALK_PLUGIN_KEY = '7263591d-dbb1-467b-a2de-0eaa28309603';
export const GA_TRACKING_ID = import.meta.env.VITE_GA_TRACKING_ID;

export const DOT_RADIUS = 2;
export const DOT_LINE_WIDTH = 3;
export const DOT_OUTSIDE_RADIUS = 3;

export const PDF_DOT_LINE_WIDTH = 2;

export const DOT_COLORS = Object.freeze({
  AI: semanticColors.valid.DEFAULT,
  ADDED: semanticColors.dot.added,
  UNSELECTED: PrimitiveColorJson['cool-grey'][500],
  DELETED: PrimitiveColorJson['cool-grey'][500],
});

export const DOT_OUTSIDE_COLOR = PrimitiveColorJson['cool-grey'][100];

export const DOT_DASH_STYLE = Object.freeze({
  LENGTH: 2, // 点線の線の長さ
  SPACING: 2, // 点線の間隔
  LINE_WIDTH: 3, // 点線の線の太さ
});
