import { useAuth } from 'entrypoint';
import { Navigate, Outlet } from 'react-router-dom';

import { queryClient } from '../api/tanstack-query';
import { FullPageSpinner } from '../components/Spinner';

export const ProtectedRoute = () => {
  const { isAuthenticated, isLoading, isInitialized } = useAuth();

  if (isLoading || !isInitialized) {
    return <FullPageSpinner />;
  }
  if (!isAuthenticated) {
    queryClient.removeQueries();
    return <Navigate to="/auth/login" />;
  }

  // If authenticated, render the child routes
  return <Outlet />;
};
